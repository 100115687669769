<template>
  <div class="text-center">
    <span v-if="gateway == 1">
      <i class="fa fa-apple" aria-hidden="true"></i> Itunes
    </span>
    <span v-else-if="gateway == 15">
      <i class="fa fa-google-wallet" aria-hidden="true"></i> Google Wallet
    </span>
    <span v-else-if="gateway == 12">
      <i class="fa fa-credit-card" aria-hidden="true"></i> CC
    </span>
    <span v-else-if="gateway ==23">
      <i class="fa fa-building-o" aria-hidden="true"></i> Gramedia
    </span>
    <span v-else-if="gateway ==24">
      <i class="fa fa-suitcase" aria-hidden="true"></i> scoopwallet
    </span>
    <span v-else-if="gateway == 28">
      <i class="fa fa-firefox" aria-hidden="true"></i> VA Permata
    </span>
    <span v-else-if="gateway == 29">
      <i class="fa fa-firefox" aria-hidden="true"></i> VA Bca
    </span>
    <span v-else-if="gateway == 30">
      <i class="fa fa-firefox" aria-hidden="true"></i> VA Mandiri
    </span>
    <span v-else-if="gateway == 31">
      <i class="fa fa-firefox" aria-hidden="true"></i> VA BNI
    </span>
     <span v-else-if="gateway == 38">
      <i class="fa fa-suitcase" aria-hidden="true"></i> ShoppePay
    </span>
     <span v-else-if="gateway == 33">
      <i class="fa fa-suitcase" aria-hidden="true"></i> Linkaja
    </span>
    <span v-else-if="gateway == 32">
      <i class="fa fa-suitcase" aria-hidden="true"></i> Gopay
    </span>
     <span v-else-if="gateway == 35">
      <i class="fa fa-firefox" aria-hidden="true"></i> CIMB Clicks
    </span>
    <span v-else-if="gateway == 36">
      <i class="fa fa-firefox" aria-hidden="true"></i> CIMB go Mobile
    </span>
    <span v-else-if="gateway == 37">
      <i class="fa fa-firefox" aria-hidden="true"></i> CIMB RekPon
    </span>
    <span v-else-if="gateway == 3">
      <i class="fa fa-ticket" aria-hidden="true"></i> FREE Purchase
    </span>
    <span v-else-if="gateway == 50">
      <i class="fa fa-ticket" aria-hidden="true"></i> Qris
    </span>
    <span v-else>
      {{ gateway }}
    </span>
  </div>
</template>

<script>
export default {
  name: "gateway",
  props: {
    gateway: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: false,
    }
  }
};
</script>